import { CustomerLifecycleFilter, CustomerLiferCycleParams } from '../type';

export function customerLifecycleFilterToParams({
  assignedCs,
  account,
}: CustomerLifecycleFilter): CustomerLiferCycleParams {
  return {
    account: account || undefined,
    assignedCs: assignedCs || undefined,
  };
}
