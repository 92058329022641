import { CustomerLifecycleFilter, CustomerLiferCycleParams } from '../type';
import { queryParamsToString } from '@pwiz/infra/ts';

export function customerLifecycleToFilter({
  assignedCs,
  account,
}: CustomerLiferCycleParams): CustomerLifecycleFilter {
  return {
    assignedCs: queryParamsToString(assignedCs),
    account: queryParamsToString(account),
  };
}
